@import "var.scss";
@media(max-width:767px){
    .footer{
        background: #F8F8F8;
        padding: 30px 15px;
        color: var(--color-text-2);
        font-size: 16px;
        .contact{
            align-items: center;
            margin-bottom: 13px;
            .icon-ct{
                margin-right: 11px;
            }
        }
        .hotline{
            @extend .contact;
        }
        .social{
            font-size: 16px;
            flex-wrap: wrap;
            margin-bottom: 12px;
            .text{
                width: 100%;
                display: block;
                margin-bottom: 10px;
            }
            .item-social{
                width: 20px;
                height: 20px;
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-right: 16px;
                margin-left: 0;
                .icon-ct{
                    font-size: 16px;
                }
            }
        }
    }
    .logo-footer{
        width: 140px;
        margin-bottom: 14px;
        display: inline-block;
        img{
            width: 100%;
            float: left;
        }
    }
    .copyright{
        margin-bottom: 24px;
    }
    .link-fpt{
        align-items: center;
        color: #757575;
    }
    .logo-fpt{
        width: 34px;
        margin-left: 10px;
        display: inline-block;
        img{
            width: 100%;
            float: left;
        }
    }
}
